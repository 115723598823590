<template>
<div>
  
    <DeezerPage target='home'></DeezerPage>

</div>
</template>

<script>
import DeezerPage from '@/views/DeezerPage.vue';

export default {
    name: 'HomeScreen',
    components: {DeezerPage},
    data() {
        return {
        }
    },
    methods: {

    },
    created() {
    }
}
</script>