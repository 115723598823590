var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.card)?_c('v-list-item',{attrs:{"two-line":""},on:{"click":_vm.click,"contextmenu":function($event){$event.preventDefault();_vm.menu = true}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.album.art.thumb}}),_c('v-overlay',{attrs:{"absolute":"","value":hover}},[_c('v-btn',{attrs:{"icon":"","large":""},on:{"click":function($event){$event.stopPropagation();return _vm.play($event)}}},[_c('v-icon',[_vm._v("mdi-play")])],1)],1)],1)]}}],null,false,1117203057)}),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.album.title)),(_vm.album.explicit)?_c('span',{staticClass:"red--text text-overline pl-2"},[_vm._v("E")]):_vm._e()]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.album.artistString))])],1),_c('v-list-item-action',[_c('v-menu',{attrs:{"offset-y":"","offset-x":"","absolue":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3765703094)},[_c('AlbumContext',{attrs:{"album":_vm.album},on:{"play":_vm.play,"download":_vm.download,"addQueue":_vm.addQueue,"share":_vm.share,"library":_vm.library}})],1)],1)],1):_vm._e(),(_vm.card)?_c('v-card',{attrs:{"max-width":"175px","max-height":"230px","color":"transparent","elevation":"0"},on:{"click":_vm.click,"contextmenu":_vm.showMenu}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',[_c('v-img',{staticClass:"rounded-lg",attrs:{"src":_vm.album.art.thumb}}),_c('v-overlay',{attrs:{"absolute":"","value":hover,"opacity":"0.5"}},[_c('v-btn',{attrs:{"fab":"","small":"","color":"white"},on:{"click":function($event){$event.stopPropagation();return _vm.play($event)}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-play")])],1)],1)],1)]}}],null,false,1537457728)}),_c('v-menu',{attrs:{"position-x":_vm.menuX,"position-y":_vm.menuY,"absolute":"","offset-y":"","close-on-click":""},model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('AlbumContext',{attrs:{"album":_vm.album},on:{"play":_vm.play,"download":_vm.download,"addQueue":_vm.addQueue,"share":_vm.share,"library":_vm.library}})],1),_c('div',{staticClass:"px-2 pt-2 pb-1 text-subtitle-2 text-center text-truncate"},[_vm._v(_vm._s(_vm.album.title))]),_c('div',{staticClass:"pb-1 px-2 text-caption text-center text-truncate"},[_vm._v(_vm._s(_vm.album.artistString))])],1):_vm._e(),(_vm.downloadDialog)?_c('DownloadDialog',{attrs:{"tracks":_vm.album.tracks},on:{"close":function($event){_vm.downloadDialog = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }